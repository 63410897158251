// This is all you.
require('./bootstrap');


//akordiyon açılınca, akordiyonun en üste gitmesini sağlıyor.
jQuery(function($){
  $('.accordion-header').each(function(){
    let $card_header = $(this);
    let $collapse_element = $card_header.next();
    $collapse_element.on('show.bs.collapse', function () {
      let $card_header_top = $card_header.offset().top;
      let $visible_collapse = $('.collapse.show');
      if($visible_collapse.length){
        let $visible_collapse_top = $visible_collapse.offset().top;
        if($visible_collapse_top < $card_header_top){
          $card_header_top -= $visible_collapse.height();
        }
      }
      $([document.documentElement, document.body]).animate({
        scrollTop: $card_header_top
      });
    });
  });
});
